<template>
  <v-btn
    class="mr-2"
    :color="isActive ? 'primary' : null"
    @click="(e) => $emit('click', e)"
    icon
    tile
    :small="isMDI"
    :x-small="!isMDI"
  >
    <v-icon>{{ displayIcon }}</v-icon>
  </v-btn>
</template>

<script>
import { mdiFormatClear } from '@mdi/js';

export default {
  name: 'WBtn',

  props: {
    isActive: Boolean,
    icon: String,
  },

  data() {
    return {
      icons: { mdiFormatClear },
    };
  },

  computed: {
    isMDI() {
      return !!this.icons[this.icon];
    },
    displayIcon() {
      return this.isMDI ? this.icons[this.icon] : this.icon;
    },
  },
};
</script>
