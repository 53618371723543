var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "mr-2",
      attrs: {
        color: _vm.isActive ? "primary" : null,
        icon: "",
        tile: "",
        small: _vm.isMDI,
        "x-small": !_vm.isMDI
      },
      on: {
        click: function(e) {
          return _vm.$emit("click", e)
        }
      }
    },
    [_c("v-icon", [_vm._v(_vm._s(_vm.displayIcon))])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }