<template>
  <div>
    <div class="container-width mb-4">
      <v-toolbar
          v-if="editor"
          flat
          :extended="$vuetify.breakpoint.xsOnly"
      >
        <v-row>
          <v-col class="pb-0" cols="12" sm="3">
            <v-select
                :value="selectedBlockType"
                :items="blockTypes"
                @input="setBlockType"
                hide-details
                dense
            ></v-select>
          </v-col>

          <v-col class="d-flex flex-wrap pb-0" cols="12" sm="9">
            <div class="mr-2">
              <WysiwygButton
                  icon="fa-bold"
                  :is-active="editor.isActive('bold')"
                  @click="() => execute('toggleBold')"
              />
              <WysiwygButton
                  icon="fa-italic"
                  :is-active="editor.isActive('italic')"
                  @click="() => execute('toggleItalic')"
              />
              <WysiwygButton
                  icon="fa-underline"
                  :is-active="editor.isActive('underline')"
                  @click="() => execute('toggleUnderline')"
              />
            </div>

            <div class="mr-2">
              <v-dialog
                  v-model="isLinkDialogVisible"
                  width="500"
              >
                <template v-slot:activator="{ on }">
                  <WysiwygButton
                      icon="fa-link"
                      :is-active="editor.isActive('link')"
                      @click="(e) => showLinkDialog(e, on.click)"
                  />
                </template>

                <v-card>
                  <v-card-title>Set Link</v-card-title>

                  <v-card-text>
                    <v-row>
                      <v-col class="d-flex align-end">
                        <v-select
                            label="Protocol"
                            v-model="newLinkProtocol"
                            :items="protocols"
                            dense
                            hide-details
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-text-field
                            label="URL"
                            v-model="newLinkLocation"
                            @input="newLinkValid = true"
                            @change="(val) => formatLinkInput(val)"
                            hide-details
                        />
                      </v-col>
                    </v-row>

                    <v-alert
                        v-if="!newLinkValid"
                        class="mb-0 mt-4"
                        dense
                        outlined
                        type="error"
                    >
                      That link appears to be invalid. Please double check the protocol and the full link location.
                    </v-alert>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                        color="error"
                        text
                        @click="isLinkDialogVisible = false"
                    >Cancel
                    </v-btn>

                    <v-btn
                        v-if="editingExistingLink"
                        color="accent"
                        text
                        @click="unsetLink"
                    >Remove Link
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn
                        :disabled="!newLinkLocation"
                        color="primary"
                        @click="setLink"
                    >{{ editingExistingLink ? 'Update' : 'Insert' }} Link
                    </v-btn>
                  </v-card-actions>

                </v-card>
              </v-dialog>
            </div>

            <div class="mr-2">
              <WysiwygButton
                  icon="fa-align-left"
                  :is-active="editor.isActive({ textAlign: 'left' })"
                  @click="() => execute('setTextAlign', 'left')"
              />
              <WysiwygButton
                  icon="fa-align-center"
                  :is-active="editor.isActive({ textAlign: 'center' })"
                  @click="() => execute('setTextAlign', 'center')"
              />
              <WysiwygButton
                  icon="fa-align-right"
                  :is-active="editor.isActive({ textAlign: 'right' })"
                  @click="() => execute('setTextAlign', 'right')"
              />
            </div>

            <div class="mr-2">
              <WysiwygButton
                  icon="fa-list-ul"
                  :is-active="editor.isActive({ textAlign: 'bulletList' })"
                  @click="() => execute('toggleBulletList')"
              />
              <WysiwygButton
                  icon="fa-list-ol"
                  :is-active="editor.isActive({ textAlign: 'orderedList' })"
                  @click="() => execute('toggleOrderedList')"
              />
            </div>

            <div class="mr-2">
              <WysiwygButton
                  icon="mdiFormatClear"
                  :is-active="false"
                  @click="removeFormatting"
              />
            </div>

            <v-menu
                offset-y
                v-if="placeholders"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    tile
                    small
                >
                  <v-icon>{{ icons.mdiCodeBraces }}</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    v-for="placeholder in placeholders"
                    :key="placeholder.value"
                    @click="() => addPlaceholder(placeholder.value)"
                >
                  {{ placeholder.label }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-divider/>
    </div>
    <editor-content :editor="editor"/>
    <div class="container-width mt-4">
      <v-divider/>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import { defaultExtensions } from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Placeholders from '@/plugins/tiptap-extensions/placeholders';
import Underline from '@tiptap/extension-underline';
import { TextSelection } from 'prosemirror-state';
import WysiwygButton from './wysiwyg-button';
import { mdiCodeBraces } from '@mdi/js';

export default {
  name: 'WYSIWYG',
  components: {
    EditorContent,
    WysiwygButton,
  },

  props: {
    value: String,
    placeholders: Array,
  },

  data() {
    return {
      icons: { mdiCodeBraces },
      editor: null,
      selectedBlockType: 'Normal',
      blockTypes: [
        'Heading 1',
        'Heading 2',
        'Heading 3',
        'Normal',
      ],
      protocols: [ 'https://', 'http://' ],
      isLinkDialogVisible: false,
      newLinkProtocol: 'https://',
      newLinkLocation: '',
      newLinkValid: true,
      editingExistingLink: false,
    };
  },

  methods: {
    // When we select a different portion of the content, we need to update the block type select box
    syncCurrentBlockType() {
      if (this.editor.isActive('heading', { level: 1 })) {
        return this.selectedBlockType = 'Heading 1';
      }

      if (this.editor.isActive('heading', { level: 2 })) {
        return this.selectedBlockType = 'Heading 2';
      }

      if (this.editor.isActive('heading', { level: 3 })) {
        return this.selectedBlockType = 'Heading 3';
      }

      this.selectedBlockType = 'Normal';
    },
    setBlockType(value) {
      // Since the v-select is stateful, we have to manually update the state or the select wont update correctly when selecting a new portion of the content
      this.selectedBlockType = value;

      switch (value) {
        case 'Heading 1':
          return this.execute('toggleHeading', { level: 1 });
        case 'Heading 2':
          return this.execute('toggleHeading', { level: 2 });
        case 'Heading 3':
          return this.execute('toggleHeading', { level: 3 });
        default:
          return this.execute('setParagraph');
      }
    },

    // This method allows us to select the full node or word for upserting a link when nothing is selected
    maybeSelectFullLink({ state, tr }) {
      const doc = state.doc;
      const selection = state.selection;
      let start = selection.from;
      let end = selection.to;

      // The nodes that are at the original bounds of the selection
      const startNode = doc.nodeAt(start);
      const endNode = doc.nodeAt(end);

      // The nodes that are at the bounds of the updated selection as we expand it
      let curStartNode = doc.nodeAt(start);
      let curEndNode = doc.nodeAt(end);

      // Typically means a full node is already selected
      if (!startNode || !endNode) {
        return true;
      }

      // Typically means multiple nodes are selected
      if (!startNode.eq(endNode)) {
        return true;
      }

      const isLinkMark = startNode.marks.length > 0 && startNode.marks.find(mark => mark.type.name === 'link');

      // Only proceed if nothing is selected OR if the whole link mark is not selected
      if (start !== end && !isLinkMark) {
        return true;
      }

      // Expand the selection backwards until the link mark or the current word is fully selected
      while (curStartNode && curStartNode.eq(startNode)) {
        // If we are in a link mark, select the whole thing, otherwise select the whole word
        if (doc.textBetween(start - 1, start) === ' ' && !isLinkMark) {
          break;
        }

        start--;

        curStartNode = doc.nodeAt(start);
      }

      // Expand the selection forwards until the link mark or the current word is fully selected
      while (curEndNode && curEndNode.eq(endNode)) {
        // If we are in a link mark, select the whole thing, otherwise select the whole word
        if (doc.textBetween(end, end + 1) === ' ' && !isLinkMark) {
          break;
        }

        end++;

        curEndNode = doc.nodeAt(end);
      }

      const textSelection = TextSelection.create(tr.doc, start, end);
      tr.setSelection(textSelection);

      return true;
    },
    showLinkDialog(e, openDialog) {
      this.editor.chain().focus().command(this.maybeSelectFullLink).run();

      const attrs = this.editor.getMarkAttributes('link');
      this.newLinkLocation = '';
      this.newLinkProtocol = 'https://';
      this.editingExistingLink = false;

      if ('href' in attrs) {
        this.formatLinkInput(attrs.href, true);
        this.editingExistingLink = true;
      }

      openDialog(e);
    },
    formatLinkInput(link, overrideProtocol = false) {
      if (link.indexOf('https://') === 0) {
        this.newLinkProtocol = 'https://';
        this.newLinkLocation = link.replace('https://', '');
      } else if (link.indexOf('http://') === 0) {
        this.newLinkProtocol = 'http://';
        this.newLinkLocation = link.replace('http://', '');
      } else {
        if (overrideProtocol) {
          this.newLinkProtocol = '';
        }
        this.newLinkLocation = link;
      }
    },
    setLink() {
      const url = `${this.newLinkProtocol}${this.newLinkLocation}`;
      let urlObj;

      try {
        urlObj = new URL(url);
      } catch (_) {
        this.newLinkValid = false;
      }

      if (!urlObj || urlObj.host.indexOf('.') < 1) {
        this.newLinkValid = false;
      }

      if (!this.newLinkValid) {
        return;
      }

      this.execute('setLink', { href: url });
      this.newLinkLocation = '';
      this.newLinkProtocol = 'https://';
      this.editingExistingLink = false;
      this.isLinkDialogVisible = false;
    },
    unsetLink() {
      this.execute('unsetLink');
      this.newLinkLocation = '';
      this.newLinkProtocol = 'https://';
      this.editingExistingLink = false;
      this.isLinkDialogVisible = false;
    },

    addPlaceholder(value) {
      this.execute('addPlaceholder', value);
    },

    removeFormatting() {
      this.execute('clearNodes');
      this.execute('unsetAllMarks');
      this.execute('setParagraph');
    },

    /**
     * this method shortens something like
     * `this.editor.chain().focus().setLink({ href: url }).run();`
     * to
     * `this.execute('setLink', { href: url })`
     */
    execute(method, ...args) {
      const editorChain = this.editor.chain();
      editorChain.focus();

      // check extensions first
      let callback = editorChain[method];

      if (!callback) {
        const commands = this.editor.commands;
        callback = commands[method];
      }

      try {
        callback(...args);

        editorChain.run();
      } catch (e) {
        console.error(`The method \`${method}\` is undefined on the editor.`);
      }
    },
  },

  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(this.value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      autofocus: 'end',
      extensions: [
        ...defaultExtensions(),
        Link.configure({
          openOnClick: false,
        }),
        TextAlign,
        BulletList,
        OrderedList,
        ListItem,
        Underline,
        Placeholders,
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML());
      },
      onSelectionUpdate: () => {
        this.syncCurrentBlockType();
      },
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
div.container-width {
  margin-left: -16px;
  margin-right: -16px;
}

.quill-placeholder {
  display: inline-block;
  padding: 2px 5px;
  margin: 2px 0;
  border: 1px solid #ccc;
  color: #555;
  background: #f7f7f7;
  border-radius: 10px;
}
</style>
