var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "container-width mb-4" },
        [
          _vm.editor
            ? _c(
                "v-toolbar",
                {
                  attrs: { flat: "", extended: _vm.$vuetify.breakpoint.xsOnly }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12", sm: "3" } },
                        [
                          _c("v-select", {
                            attrs: {
                              value: _vm.selectedBlockType,
                              items: _vm.blockTypes,
                              "hide-details": "",
                              dense: ""
                            },
                            on: { input: _vm.setBlockType }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-wrap pb-0",
                          attrs: { cols: "12", sm: "9" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _c("WysiwygButton", {
                                attrs: {
                                  icon: "fa-bold",
                                  "is-active": _vm.editor.isActive("bold")
                                },
                                on: {
                                  click: function() {
                                    return _vm.execute("toggleBold")
                                  }
                                }
                              }),
                              _c("WysiwygButton", {
                                attrs: {
                                  icon: "fa-italic",
                                  "is-active": _vm.editor.isActive("italic")
                                },
                                on: {
                                  click: function() {
                                    return _vm.execute("toggleItalic")
                                  }
                                }
                              }),
                              _c("WysiwygButton", {
                                attrs: {
                                  icon: "fa-underline",
                                  "is-active": _vm.editor.isActive("underline")
                                },
                                on: {
                                  click: function() {
                                    return _vm.execute("toggleUnderline")
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: { width: "500" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c("WysiwygButton", {
                                              attrs: {
                                                icon: "fa-link",
                                                "is-active": _vm.editor.isActive(
                                                  "link"
                                                )
                                              },
                                              on: {
                                                click: function(e) {
                                                  return _vm.showLinkDialog(
                                                    e,
                                                    on.click
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3747226001
                                  ),
                                  model: {
                                    value: _vm.isLinkDialogVisible,
                                    callback: function($$v) {
                                      _vm.isLinkDialogVisible = $$v
                                    },
                                    expression: "isLinkDialogVisible"
                                  }
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-card-title", [_vm._v("Set Link")]),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "d-flex align-end"
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      label: "Protocol",
                                                      items: _vm.protocols,
                                                      dense: "",
                                                      "hide-details": ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newLinkProtocol,
                                                      callback: function($$v) {
                                                        _vm.newLinkProtocol = $$v
                                                      },
                                                      expression:
                                                        "newLinkProtocol"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "URL",
                                                      "hide-details": ""
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        _vm.newLinkValid = true
                                                      },
                                                      change: function(val) {
                                                        return _vm.formatLinkInput(
                                                          val
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newLinkLocation,
                                                      callback: function($$v) {
                                                        _vm.newLinkLocation = $$v
                                                      },
                                                      expression:
                                                        "newLinkLocation"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          !_vm.newLinkValid
                                            ? _c(
                                                "v-alert",
                                                {
                                                  staticClass: "mb-0 mt-4",
                                                  attrs: {
                                                    dense: "",
                                                    outlined: "",
                                                    type: "error"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " That link appears to be invalid. Please double check the protocol and the full link location. "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "error",
                                                text: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.isLinkDialogVisible = false
                                                }
                                              }
                                            },
                                            [_vm._v("Cancel ")]
                                          ),
                                          _vm.editingExistingLink
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "accent",
                                                    text: ""
                                                  },
                                                  on: { click: _vm.unsetLink }
                                                },
                                                [_vm._v("Remove Link ")]
                                              )
                                            : _vm._e(),
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: !_vm.newLinkLocation,
                                                color: "primary"
                                              },
                                              on: { click: _vm.setLink }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.editingExistingLink
                                                    ? "Update"
                                                    : "Insert"
                                                ) + " Link "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _c("WysiwygButton", {
                                attrs: {
                                  icon: "fa-align-left",
                                  "is-active": _vm.editor.isActive({
                                    textAlign: "left"
                                  })
                                },
                                on: {
                                  click: function() {
                                    return _vm.execute("setTextAlign", "left")
                                  }
                                }
                              }),
                              _c("WysiwygButton", {
                                attrs: {
                                  icon: "fa-align-center",
                                  "is-active": _vm.editor.isActive({
                                    textAlign: "center"
                                  })
                                },
                                on: {
                                  click: function() {
                                    return _vm.execute("setTextAlign", "center")
                                  }
                                }
                              }),
                              _c("WysiwygButton", {
                                attrs: {
                                  icon: "fa-align-right",
                                  "is-active": _vm.editor.isActive({
                                    textAlign: "right"
                                  })
                                },
                                on: {
                                  click: function() {
                                    return _vm.execute("setTextAlign", "right")
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _c("WysiwygButton", {
                                attrs: {
                                  icon: "fa-list-ul",
                                  "is-active": _vm.editor.isActive({
                                    textAlign: "bulletList"
                                  })
                                },
                                on: {
                                  click: function() {
                                    return _vm.execute("toggleBulletList")
                                  }
                                }
                              }),
                              _c("WysiwygButton", {
                                attrs: {
                                  icon: "fa-list-ol",
                                  "is-active": _vm.editor.isActive({
                                    textAlign: "orderedList"
                                  })
                                },
                                on: {
                                  click: function() {
                                    return _vm.execute("toggleOrderedList")
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _c("WysiwygButton", {
                                attrs: {
                                  icon: "mdiFormatClear",
                                  "is-active": false
                                },
                                on: { click: _vm.removeFormatting }
                              })
                            ],
                            1
                          ),
                          _vm.placeholders
                            ? _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      tile: "",
                                                      small: ""
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.icons.mdiCodeBraces
                                                    )
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1172395153
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    _vm._l(_vm.placeholders, function(
                                      placeholder
                                    ) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: placeholder.value,
                                          on: {
                                            click: function() {
                                              return _vm.addPlaceholder(
                                                placeholder.value
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(placeholder.label) +
                                              " "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("v-divider")
        ],
        1
      ),
      _c("editor-content", { attrs: { editor: _vm.editor } }),
      _c("div", { staticClass: "container-width mt-4" }, [_c("v-divider")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }