import { mergeAttributes, Node } from '@tiptap/core';

/**
 * Documentation for extensions can be found at
 * https://www.tiptap.dev/guide/extend-extensions#schema
 * https://www.tiptap.dev/api/schema/
 * https://www.tiptap.dev/guide/node-views/examples
 */
const Placeholders = Node.create({
  name: 'placeholders',
  group: 'inline',
  inline: true,
  atom: true,

  addCommands() {
    return {
      addPlaceholder: (placeholder) => ({ commands }) => {
        commands.insertContent(`<span class="quill-placeholder" data-sc-property="${placeholder}"><span contenteditable="false"></span></span>`);
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.quill-placeholder',
      },
    ];
  },

  // This is parsing the placeholder from the data property in the content and storing it in the HTMLattributes argument used below
  addAttributes() {
    return {
      'data-sc-property': {
        default: null,
        parseHTML: element => {
          return {
            'data-sc-property': element.dataset.scProperty,
          };
        },
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    // take the parsed attributes above and merge them with ones we want to add. They will be applied to the node in the tiptap editor (this is the content stored in the DB)
    const merged = mergeAttributes(
      HTMLAttributes,
      { class: 'quill-placeholder' },
    );

    return [ 'span', merged ];
  },

  // This is how the custom node is rendered in the editor (but not how it is stored in the content that is saved to the DB)
  addNodeView() {
    return ({ node }) => {
      const dom = document.createElement('span');
      dom.classList.add('quill-placeholder');
      dom.innerHTML = node.attrs['data-sc-property'];

      return {
        dom,
      };
    };
  },
});

export default Placeholders;
